<template>
    <el-table v-if="employeeReminders.length > 0" :data="employeeReminders" style="width: 100%">
        <el-table-column :label="$t('employee_details.note')" prop="note" />
        <!-- <el-table-column :label="$t('employee_details.sms')" prop="sms">
            <template slot-scope="scope">
                {{ scope.row.sms ? $t('common.yes') : $t('common.no') }}
            </template>
        </el-table-column> -->
        <el-table-column :label="$t('employee_details.created_by')" prop="created_by" width="150" />
        <el-table-column :label="$t('employee_details.email')" prop="email" width="100" align="center">
            <template slot-scope="scope">
                {{ scope.row.email ? $t('common.yes') : $t('common.no') }}
            </template>
        </el-table-column>
        <el-table-column :label="$t('employee_details.date')" prop="reminder_date" sortable width="100" />
        <el-table-column width="50">
            <template slot-scope="scope">
                <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.delete')" placement="left">
                    <el-button type="danger" size="mini" @click="handleDelete(scope.row.uuid)">
                        <fa-icon :icon="['fas', 'trash']" fixed-width />
                    </el-button>
                </el-tooltip>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        employeeReminders: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleDelete(uuid) {
            this.$emit('handleDelete', uuid);
        },
    },
};
</script>
